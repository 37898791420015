import React, { useEffect, useState, useMemo } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { getOr, get } from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import {
  Hr, SearchSuggestions,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT,
  FETCH_REGISTRATION_OPTIONS_BY_DEADLINE,
  FETCH_UNJOIN_USERS_BY_EVENT,
  FETCH_ROLE_BY_PERMISSION,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { PermissionButton, Input, RedirectButton } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import UserList from '../../Components/UserList/Index';
import DefaultImage from '../../Images/team-dum.png';
import REMOVE_ICON from '../../Images/cross.png';
import Search from '../../Components/Search/Index';
import { INVITE_EXITING_USER } from '../../GraphQL/Mutations';
import Permissions from '../../Constants/PermissionConstrants';

export const FETCH_UNJOIN_USERS = gql`
  query fetchUnregisteredUsers($userId: Int!, $eventId: Int!){
    fetchUnregisteredUsers(userId: $userId, eventId: $eventId){
      id
      firstname
      lastname
      code
      profileImageUrl
      fetchSchools { 
        name
      }
    }
  }
`;

const InviteExistingUser = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId } = useParams();
  const { userType } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();
  const [fetchRegistrationOptions,
    { data: registrationOptions }] = useLazyQuery(FETCH_REGISTRATION_OPTIONS_BY_DEADLINE);
  const registrations = getOr([], 'registrationOptionByDeadline', registrationOptions);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [clickStatus, setClickStatus] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [fetchRoleFromPermission, { data: dataRole }] = useLazyQuery(
    FETCH_ROLE_BY_PERMISSION,
  );

  const role = getOr({}, 'fetchRoleFromPermission', dataRole);

  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  const teamMemeberPermission = useMemo(() => (checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER)),
    [checkPermission]);
  const addJudgePermission = useMemo(() => (checkPermission(Permissions.CAN_ADD_JUDGE)),
    [checkPermission]);

  const [fetchUnregisteredUsers] = useLazyQuery(
    FETCH_UNJOIN_USERS,
    {
      onCompleted: (data1) => {
        setTotalUsers(
          data1.fetchUnregisteredUsers.filter(({ status }) => status === 'active'),
        );
      },
    },
  );
  const [fetchUnregisteredUsersByEvent] = useLazyQuery(
    FETCH_UNJOIN_USERS_BY_EVENT,
    {
      onCompleted: (data1) => {
        setTotalUsers(
          data1.fetchUnregisteredUsersByEvent.filter(({ status }) => status === 'active'),
        );
      },
    },
  );
  useHeaderComponentsMutation({
    title: 'INVITE EXISITING USER(S)',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  const { params, onChange, setParams } = useFormParams({
  });

  useEffect(() => {
    if (currentUser && eventId) {
      fetchRegistrationOptions({
        variables: {
          tenantId: currentUser.tenantId,
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptions, eventId]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchUnregisteredUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchUnregisteredUsers, eventId, teamMemeberPermission]);

  useEffect(() => {
    if (currentUser && eventId && addJudgePermission) {
      fetchUnregisteredUsersByEvent({
        variables: {
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchUnregisteredUsersByEvent, eventId, addJudgePermission]);

  useEffect(() => {
    if (currentUser && addJudgePermission) {
      fetchRoleFromPermission({
        variables: {
          title: `${Permissions.CAN_JOIN_JUDGE_COMPETITION}`,
        },
      });
    }
  }, [addJudgePermission, currentUser, fetchRoleFromPermission]);

  const getSelectedUser = (obj) => {
    setClickStatus(false);
    setSearchStringLength(0);
    selectedUsers.push(obj);
    params[`userId_${selectedUsers.length - 1}`] = obj.id;
  };

  const searchUsers = (e) => {
    setSearchStringLength(e.target.value.length);
    setFilteredUsers(
      totalUsers.filter(
        (user) => ((`${user.firstname} ${user.lastname}`).toLowerCase()).includes(e.target.value),
      ),
    );
  };

  const deleteUser = (user) => {
    const index = selectedUsers.indexOf(user);
    if (index !== -1) {
      selectedUsers.splice(index, 1);
    }
    setSelectedUsers([...selectedUsers]);
  };

  const [invitation, { loading: invitationLoading }] = useMutation(INVITE_EXITING_USER, {
    onCompleted: () => {
      history.push(`/event-manage/${parseInt(eventId, 10)}`);
    },
  });


  const onSubmit = (e) => {
    const invitations = [];
    e.preventDefault();
    if (checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER)) {
      const len = Object.values(params).length;
      for (let i = 0; i < len; i += 2) {
        invitations.push({
          userId: parseInt(Object.values(params)[i], 10),
          registrationOptionId: parseInt(Object.values(params)[i + 1], 10),
        });
      }
      params.invitations = invitations;
    } else if (checkPermission(Permissions.CAN_ADD_JUDGE) && role) {
      const len = Object.values(params).length;
      for (let i = 0; i < len; i += 1) {
        invitations.push({
          userId: parseInt(Object.values(params)[i], 10),
          roleId: parseInt(get('id', role), 10),
        });
      }
      params.invitations = invitations;
    }
    invitation({
      variables: {
        ...params,
        invitableId: parseInt(eventId, 10),
        invitableType: 'Event',
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <Search
            placeholder="Search"
            onChange={searchUsers}
          />
          {filteredUsers && (searchStringLength > 0 || clickStatus === true)
            ? (
              <>
                {filteredUsers.map((obj) => (
                  <SearchSuggestions
                    onClick={() => getSelectedUser(obj)}
                    onChange={searchUsers}
                  >
                    <span id={obj.id}>
                      {`${obj.firstname} ${obj.lastname}`}
                    </span>
                  </SearchSuggestions>
                ))}
              </>
            )
            : null}
          <div className="mt-4" />
          <div className="mt-4" />
          <Hr />
          <Form onSubmit={onSubmit}>
            {selectedUsers.map((user, index) => (
              <>
                <div key={user.id}>
                  <UserList
                    user={user}
                    onClick={() => deleteUser(user)}
                    imageAttached={DefaultImage}
                    removeicon={REMOVE_ICON}
                    name={`userId_${index}`}
                    id={`userId_${index}`}
                    params={params}
                    setParams={setParams}
                  />
                  {(checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER)
                   || checkPermission(Permissions.CAN_MANAGE_REGION))
                   && (userType === 'student' || userType === 'coach'
                   || userType === 'guest')
                    ? (
                      <>
                        <Input type="select" params={params} onChange={onChange} name={`registrationOptionId_${index}`} id={`registrationOptionId_${index}`}>
                          <option value="" disabled>Select Registration Options</option>
                          {registrations && registrations.map((obj) => (
                            <option key={obj.id} value={obj.id}>{obj.title}</option>
                          ))}
                        </Input>
                        <div className="mt-4" />
                      </>
                    )
                    : null}
                  <Hr />
                </div>
              </>
            ))}
            <div className="mt-4" />
            <PermissionButton
              loading={invitationLoading}
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="none"
              htmltype="submit"
              checkPermission={(checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER))
              || checkPermission(Permissions.CAN_ADD_JUDGE)}
            >
INVITE USER
            </PermissionButton>
          </Form>
          <RedirectButton backgroundcolor="#FFF" fontColor="rgba(244, 171, 55, 1)" border="2px solid #f3AA44" addNewText="INVITE NEW USER" addNewLink={`/event-manage/${eventId}/invite-new-user/student`} />
          <div className="mt-4" />
        </div>
      </div>
    </>
  );
};
export default InviteExistingUser;
